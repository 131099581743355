import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { Wrapper } from '@dayancloud/common/components'
import { Link } from 'gatsby'
import { CmsContentService } from '@dayancloud/common/service'
import {
  Media,
  color,
  typography,
  multiLineTextEllipsis,
  transition,
  hideSMDown,
} from '@dayancloud/common/theme'

const NewsContainer = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
  ${Media.lessThan(Media.small)} {
    flex-direction: column;
  }
`
const LeftNews = styled.div`
  width: 580px;
  display: flex;
  flex-direction: column;
  font-size: ${typography.textSmall};
  .news-img {
    width: 100%;
    height: 300px;
    background: #adadb9;
    border-radius: 10px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .title {
    font-size: 20px;
    color: #182940;
    margin: 30px 0 20px;
    ${multiLineTextEllipsis(2)};
  }
  .content {
    width: 533px;
    color: #828995;
    margin-bottom: 20px;
    ${multiLineTextEllipsis(3)};
    ${hideSMDown};
  }
  .date {
    color: #999999;
  }
  transition: ${transition(['color'])};
  &:hover {
    .title {
      color: ${color.primary};
    }
  }
  ${Media.lessThan(Media.small)} {
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    padding: 10px 0;
    .news-img {
      width: 100px;
      height: 66px;
      border-radius: 5px;
      flex-shrink: 0;
    }
    .contents {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 10px;
    }
    .title {
      margin: 0;
      font-size: ${typography.text};
      ${multiLineTextEllipsis(2)};
    }
    .date {
      font-size: ${typography.textSmall};
      padding-top: 5px;
    }
  }
`

const RightNews = styled.div`
  width: 580px;
  margin-top: -10px;
  ${Media.lessThan(Media.small)} {
    width: 100%;
    margin-top: 0;
  }
`

const RightNewsItem = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ececec;
  padding: 10px;
  margin-bottom: 12px;
  .news-img {
    width: 120px;
    height: 80px;
    background: #c9c9c9;
    border-radius: 4px;
    flex-shrink: 0;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .contents {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 76px;
    margin: 0 20px;
    padding: 6px 0;
  }
  .title {
    font-size: ${typography.text};
    color: #2f323e;
    ${multiLineTextEllipsis(2)};
  }
  .date {
    font-size: ${typography.textSmall};
    color: #999999;
  }
  &:last-child {
    border-bottom: none;
  }
  transition: ${transition(['background-color', 'color'])};
  &:hover {
    background-color: #f6f7fd;
    border-radius: 6px;
    .title {
      color: ${color.primary};
    }
  }
  ${Media.lessThan(Media.small)} {
    padding: 8px 0;
    .news-img {
      width: 100px;
      height: 66px;
    }
    .contents {
      margin-left: 10px;
      padding: 0;
    }
  }
`

class TopFiveNews extends PureComponent {
  render() {
    const { topFiveNews } = this.props
    const { node: firstNews } = topFiveNews[0]
    const restNews = topFiveNews.slice(1)
    return (
      <NewsContainer onClick={this.enLarge}>
        <Link to={`/news/${firstNews.slug}`}>
          <LeftNews>
            <div className='news-img'>
              <img
                src={CmsContentService.getCoverImg(firstNews.content.content)}
                alt={firstNews.title}
              />
            </div>
            <div className='contents'>
              <div className='title'>{firstNews.title}</div>
              <div className='content'>
                {CmsContentService.getAbstract(firstNews.content.content)}
              </div>
              <div className='date'>{firstNews.createdAt}</div>
            </div>
          </LeftNews>
        </Link>
        <RightNews>
          {restNews.map(({ node: { content, title, createdAt, slug } }, index) => {
            return (
              <Link to={`/news/${slug}`} key={index}>
                <RightNewsItem>
                  <div className='news-img'>
                    <img src={CmsContentService.getCoverImg(content.content)} alt={title} />
                  </div>
                  <div className='contents'>
                    <div className='title'>{title}</div>
                    <div className='date'>{createdAt}</div>
                  </div>
                </RightNewsItem>
              </Link>
            )
          })}
        </RightNews>
      </NewsContainer>
    )
  }
}
export default TopFiveNews

import React from 'react'
import styled from 'styled-components'
import { Media, typography } from '../../../../common/theme'
import { Billboard, H1, Button } from '@dayancloud/common/components'
import { API, Axios } from '@dayancloud/common/utils'

const BannerContainer = styled.div`
  position: relative;
  height: 100vh;
  z-index: 0;
  background: #ccc;
  font-size: ${typography.h4};
  ${H1} {
    font-size: 60px;
    margin: 26px 0;
    height: 58px;
    line-height: 50px;
  }
  .text {
    width: 200px;
    height: 40px;
    line-height: 40px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    font-size: ${typography.h4};
  }
  .desc {
    width: 787px;
    line-height: 28px;
    opacity: 0.6;
    font-size: ${typography.text};
  }
  ${Media.lessThan(Media.small)} {
    height: auto;
    width: 100%;
    .desc {
      width: 100%;
      font-size: 11.2px;
      line-height: 19.6px;
    }
    .video {
      margin: 20px 0;
    }
    a {
      width: 150px;
    }
    ${H1} {
      font-size: 36px;
      height: 81px;
      line-height: 40px;
      margin: 15.5px 0 17.5px 0;
    }
    .text {
      width: 136px;
      height: 28px;
      line-height: 26px;
      font-size: 12.6px;
    }
  }
`

const BannerText = styled.div`
  padding-top: 70px;
  height: calc(100vh - 220px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  ${Media.lessThan(Media.small)} {
    height: 480px;
    padding: 0 30px;
    margin-top: 0;
    padding-top: 20px;
  }
`

const InputContent = styled.div`
  margin-top: 73px;
  height: 44px;
  .inputPhone {
    width: 266px;
    height: 44px;
    background: #ffffff;
    border-radius: 2px 0px 0px 2px;
    color: #646c7a;
    font-size: ${typography.text};
    border: none;
    padding: 0 16px;
    outline-style: none;
  }
  button {
    width: 216px;
    background: #6b6cff;
    border-radius: 0px 2px 2px 0px;
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    line-height: 24px;
    border: none;
    :hover {
      background: #6b6cff;
    }
  }
  ${Media.lessThan(Media.small)} {
    margin-top: 43px;
    .inputPhone {
      width: 145px;
      font-size: 11px;
      height: 30px;
    }
    button {
      height: 30px;
      width: 128px;
      font-size: 11px;
      padding: 0;
    }
  }
`

class SassBanner extends React.PureComponent {
  state = { value: '' }
  handleChange = event => {
    this.setState({ value: event.target.value })
  }
  handleClick = async e => {
    const instance = new Axios(API.auth.host)
    await instance
      .post(API.auth.isPhoneRegister, {
        checkType: '2',
        value: this.state.value.toString(),
      })
      .then(res => {
        if (typeof window !== 'undefined') {
          let newPage = window.open('about:blank')
          if (res.code === 'Common.Success') {
            newPage.location.href = `https://task.dayancloud.com/sso/register?=Bregister&phone=${encodeURIComponent(
              this.state.value,
            )}`
          } else {
            newPage.location.href = `https://task.dayancloud.com/sso`
          }
        }
      })
  }
  render() {
    const { fluid } = this.props
    return (
      <BannerContainer>
        <BannerText>
          <div className='text'>开启光速云重建时代</div>
          <H1>全SaaS化实景三维云计算平台</H1>
          <div className='desc'>
            大雁云是在海量机器秒级调度的基础上，提供的自助式实景三维重建云服务，
            操作便捷，只需一键上传即可将二维图片转化为高质量三维数字模型。
          </div>
          <InputContent>
            <input
              type='text'
              value={this.state.value}
              onChange={this.handleChange}
              placeholder='请输入手机号'
              className='inputPhone'
            />
            <Button
              variant='outlined'
              id='Banner-zclz'
              rel='nofollow'
              onMouseDown={this.handleClick}
            >
              注册立赠200元大雁券
            </Button>
          </InputContent>
        </BannerText>
        <Billboard fluid={fluid} />
      </BannerContainer>
    )
  }
}

export default SassBanner

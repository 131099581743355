import React from 'react'
import { graphql, Link } from 'gatsby'
import {
  SEO,
  Modal,
  Layout,
  FadeSwiper,
  Swiper,
  H2,
  // Divider,
  // Button,
} from '@dayancloud/common/components'

import {
  AdvantageItemWrap,
  SceneWrap,
  AdvantageItem,
  ApplyContainer,
  AdvantageWrap,
  SceneItemWrap,
  SceneItem,
  FeatureItemWrap,
  FeatureItem,
  DescWrap,
  NewsContainer,
  HonorContainer,
  FeatureItemContainer,
  FeatureItemList,
  Advantage,
  FeatureItemsContainer,
  TopContainer,
  H2Title,
  // UpgradeModalContent,
} from './index.atom'

import FeatureIcon01 from '../images/index/featureIcon01.png'
import FeatureIcon02 from '../images/index/featureIcon02.png'
import FeatureIcon03 from '../images/index/featureIcon03.png'
import TopFiveNews from '../molecules/index/topFiveNews'

import AdvantageImg01 from '../images/index/advantage01.png'
import AdvantageImg02 from '../images/index/advantage02.png'
import AdvantageImg03 from '../images/index/advantage03.png'
import AdvantageImg04 from '../images/index/advantage04.png'

import AdvantageHoverImg01 from '../images/index/advantageHover01.png'
import AdvantageHoverImg02 from '../images/index/advantageHover02.png'
import AdvantageHoverImg03 from '../images/index/advantageHover03.png'
import AdvantageHoverImg04 from '../images/index/advantageHover04.png'

import SceneImg01 from '../images/index/scene01.png'
import SceneImg02 from '../images/index/scene02.png'
import SceneImg03 from '../images/index/scene03.png'
import SceneImg04 from '../images/index/scene04.png'
import SceneImg05 from '../images/index/scene05.png'
import SceneImg06 from '../images/index/scene06.png'

import ScenePhone01 from '../images/index/scencePhone01.png'
import ScenePhone02 from '../images/index/scencePhone02.png'
import ScenePhone03 from '../images/index/scencePhone03.png'
import ScenePhone04 from '../images/index/scencePhone04.png'
import ScenePhone05 from '../images/index/scencePhone05.png'
import ScenePhone06 from '../images/index/scencePhone06.png'

import TryBanner from '../molecules/index/try-banner'
// import RebuildBanner from '../molecules/index/rebuild-banner'
import AppletBanner from '../molecules/index/applet-banner'
// import BentleBanner from '../molecules/index/bentle-banner'
// import SkythreeBanner from '../molecules/index/skythree-banner'
// import NewsBanner from '../molecules/index/news-banner'
// import ComboBanner from '../molecules/index/combo-banner'
// import SpringBanner from '../molecules/index/spring-banner'
// import GeographyBanner from '../molecules/index/geography-banner'
// import Cooperate from '../molecules/index/cooperate-banner'
import SassBanner from '../molecules/index/sass-banner'
import CloudServiceContainer from '../molecules/index/cloud-service'
import ContactUs from '../molecules/index/contact-us'
// import SummberBanner from '../molecules/index/summer-banner'

// import upgradeMessageBg from '../images/index/upgrade-message-bg.png'
// import upgradeMessageBgMobile from '../images/index/upgrade-message-bg-mobile.png'

import useMedia from 'use-media'

const featureList = [
  {
    icon: FeatureIcon01,
    title: '注册立赠200元大雁券',
    desc: '约免费重建2000张照片，普通会员约免费重建50GP任务！',
    id: 'Ban-zclz',
    link: 'https://task.dayancloud.com/sso/register',
  },
  {
    icon: FeatureIcon02,
    title: '大客户专属权益',
    desc: '累积充值满指定金额，享返一定比例大雁券奖励等多重专属权益！',
    id: '',
    link: '',
  },
  {
    icon: FeatureIcon03,
    title: '合伙人招募计划',
    desc: '加入大雁云代理推广，享高推广佣金，最高可达30%！',
    id: 'Ban-zmjh',
    link: 'https://www.dayancloud.com/cooperate.html',
  },
]

const advantageList = [
  {
    icon: AdvantageImg01,
    hoverIcon: AdvantageHoverImg01,
    text: '注册、创建账户',
    link: 'https://task.dayancloud.com/sso/register',
  },
  {
    icon: AdvantageImg02,
    hoverIcon: AdvantageHoverImg02,
    text: '下载大雁云客户端',
    title1: '安装，启动应用',
    link: 'https://dayancloud.com/download.html',
    link1: 'https://dayancloud.com/download.html',
  },
  {
    icon: AdvantageImg03,
    hoverIcon: AdvantageHoverImg03,
    text: '一键数据上传',
    title1: '影像数据提交教程',
    title2: '区块数据提交教程',
    link1: 'https://www.dayancloud.com/support/new-work001/',
    link2: 'https://www.dayancloud.com/support/prepare-data/',
  },
  {
    icon: AdvantageImg04,
    hoverIcon: AdvantageHoverImg04,
    text: '下载、分享生产成果',
  },
]

const sceneList = [
  {
    img: SceneImg01,
    phoneImg: ScenePhone01,
    title: '智慧城市',
    desc:
      '实景三维重建系统可对城市进行高精度的实景建模，模型及数据可云端共享，为城市建设、城市规划、违建巡查、城市管理提供便利，助力智慧城市建设。',
  },
  {
    img: SceneImg02,
    phoneImg: ScenePhone02,
    title: '工程建设管控',
    desc:
      '三维实景模型可以直观地反映地物外观、位置、高度等属性，为设计提供有力数据支撑，有效补充项目的外业数据，提升项目的内业质量。',
  },
  {
    img: SceneImg03,
    phoneImg: ScenePhone03,
    title: '文物数字化',
    desc: '实景三维测绘有助于制定科学的、针对性的保护方案，从而对文物、古迹遗址展开更有效的保护。',
  },
  {
    img: SceneImg04,
    phoneImg: ScenePhone04,
    title: '应急测绘',
    desc:
      '实景三维模型可快速还原真实的灾后现场，有助于更全面观测灾后情况，进一步降低调查人员的劳动强度和作业风险，提高地质灾害应急调查工作的效率。',
  },
  {
    img: SceneImg05,
    phoneImg: ScenePhone05,
    title: '矿业领域',
    desc:
      '实景三维重建实现矿山监测管理从二维向三维，从单一数据向多元数据的转变，数字化矿山的实现，有助于更科学有效的治理矿山生态系统。',
  },
  {
    img: SceneImg06,
    phoneImg: ScenePhone06,
    title: '乡村规划',
    desc:
      '随着城市和乡村的快速发展，城市和乡村的建筑、交通、水系、景观和基础设施建设已今非昔比，传统的城乡规划基于二维的图纸已难以满足当前要求，基于倾斜摄影的实景三维模型在城乡规划中的作用越来越凸显。',
  },
]

const SwiperSwitch = ({ children, ...rest }) => {
  const isWide = useMedia({ minWidth: 768 })
  return isWide ? (
    <FadeSwiper {...rest}>{children}</FadeSwiper>
  ) : (
    <Swiper {...rest}>{children}</Swiper>
  )
}
class Index extends React.PureComponent {
  state = {
    // showUpgradeModal: true,
    showModal: false,
    showVideo: false,
    headerBg: '#ffffff',
    isBorder: false,
    index: 0,
  }
  videoRef = null
  CloseModal(event) {
    if (event) event.preventDefault()
    this.setState({ showModal: false })
  }
  ShowVideo() {
    this.setState({ showVideo: true })
    if (this.videoRef) this.videoRef.play()
  }
  CloseVideo(event) {
    if (event) event.preventDefault()
    if (this.videoRef) this.videoRef.pause()
    this.setState({ showVideo: false })
  }

  scollToSubmitForm = anchorName => {
    let anchorElement = document.getElementById(anchorName)
    if (anchorElement) {
      anchorElement.scrollIntoView({ behavior: 'smooth' })
    }
  }
  handleScroll = e => {
    if (e.currentTarget.scrollY) {
      this.setState({ isBorder: false, headerBg: '#ffffff' })
    } else {
      this.setState({ isBorder: false, headerBg: '#ffffff' })
    }
  }

  componentDidMount() {
    // const endTime = new Date('2022-10-29 00:00:00').getTime()
    // if (new Date().getTime() > endTime) {
    //   this.setState({ showUpgradeModal: false })
    // } else {
    //   const dom = document.querySelector('.upgradeMessageModal')
    //   if (dom) {
    //     dom.parentElement.parentElement.style.borderRadius = '10px'
    //     dom.parentElement.parentElement.style.boxShadow = '0px 10px 24px 6px rgba(220,220,229,0.3)'
    //   }
    // }
    window.addEventListener('scroll', this.handleScroll, true)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll, true)
  }

  handleNextClick = () => {
    const length = this.props.pageContext.honorList.edges.length
    this.setState(prev => ({
      index: prev.index + 1 === length ? 0 : prev.index + 1,
    }))
  }

  handlePrevClick = () => {
    const length = this.props.pageContext.honorList.edges.length
    this.setState(prev => ({
      index: prev.index === 0 ? length - 1 : prev.index - 1,
    }))
  }

  render() {
    const { showVideo, showUpgradeModal } = this.state
    const {
      data,
      location,
      pageContext: { topFiveNews, friendLink, honorList },
    } = this.props
    return (
      <Layout
        location={location}
        topFiveNews={topFiveNews}
        showFriendLink={true}
        friendLink={friendLink}
        headerBg={this.state.headerBg}
        isBorder={this.state.isBorder}
      >
        <SEO
          title='大雁云-全SaaS化实景三维云计算平台,开启光速云重建时代'
          keywords='实景建模,三维实景建模,倾斜摄影三维建模,实景三维云处理服务,实景三维重建'
          description='大雁云是瑞云科技推出的实景三维云端重建模型云处理服务,用户将无人机倾斜摄影拍摄的二维图像一键上传至平台,可快速转化为三维数字模型,海量机器秒级调度,成倍提升实景三维重建处理效率。'
          ogUrl='https://www.dayancloud.com'
          ogType='website'
        />
        <TopContainer>
          <SwiperSwitch
            navBottom={260}
            arrowBg='rgba(255,255,255,0.1)'
            hoverArrowBg='rgba(255,255,255,0.2)'
            arrowLeft='160px'
            arrowRight='160px'
          >
            {/* <SpringBanner /> */}
            {/* <SkythreeBanner /> */}
            {/* <RebuildBanner /> */}
            {/* <GeographyBanner /> */}
            <SassBanner fluid={data.sassBanner.childImageSharp.fluid} />
            {/* <SummberBanner /> */}
            <AppletBanner />
            {/* <BentleBanner /> */}
          </SwiperSwitch>
          <FeatureItemContainer>
            <FeatureItemWrap>
              {featureList.map((item, index) => {
                return (
                  <FeatureItem key={index}>
                    <FeatureItemList id={item.id} as={Link} to={item.link}>
                      <img src={item.icon} alt='' />
                      <div className='content'>
                        <div className='title'>{item.title}</div>
                        <div className='desc'>{item.desc}</div>
                      </div>
                    </FeatureItemList>
                  </FeatureItem>
                )
              })}
            </FeatureItemWrap>
          </FeatureItemContainer>
        </TopContainer>
        <CloudServiceContainer />
        <FeatureItemsContainer>
          <Advantage>
            <AdvantageWrap>
              <H2 className='title'>
                一键上传数据
                <br />
                即可获得实景三维重建模型
              </H2>
              <AdvantageItemWrap>
                {advantageList.map((item, index) => {
                  return (
                    <AdvantageItem key={index}>
                      <img className='dishoverImg' src={item.icon} alt={item.text} />
                      <img className='hoverImg' src={item.hoverIcon} alt={item.text} />
                      <div className='text'>
                        <a href={item.link}>{item.text}</a>
                      </div>
                      <div className='content'>
                        <div className='contentTitle1'>
                          <a href={item.link1}>{item.title1}</a>
                        </div>
                        <div className='contentTitle2'>
                          <a href={item.link2}>{item.title2}</a>
                        </div>
                      </div>
                    </AdvantageItem>
                  )
                })}
              </AdvantageItemWrap>
            </AdvantageWrap>
          </Advantage>
          <DescWrap>
            <div className='schedule'>
              <ul>
                <li>一键上传数据</li>
                <li>支持单镜头无人机、五镜头无人机、单反相机、手机等拍摄的照片；</li>
                <li>支持其他软件制作的通用格式空三文件；</li>
              </ul>
              <ul>
                <li>智能分析、重建</li>
                <li>
                  自动识别每张照片的相对位置和方向，对点位、距离、面积等关键数据智能化分析，全自动模型重建。
                </li>
              </ul>
              <ul>
                <li>一键下载、分享成果</li>
                <li>支持多格式产品输出，一键下载、在线分享生产项目成果。</li>
              </ul>
            </div>
            <div className='videoContent'>
              <video
                src='https://www.dayancloud.com/dayan_client_video_2021_09.mp4'
                allowFullScreen={true}
                controls={true}
                autoPlay={false}
                ref={this.setVideoRef}
              />
            </div>
          </DescWrap>
        </FeatureItemsContainer>
        <ContactUs />
        <ApplyContainer>
          <SceneWrap>
            <H2Title>实景三维重建应用场景</H2Title>
            <SceneItemWrap className='pcScene'>
              {sceneList.slice(0, 3).map((item, index) => {
                return (
                  <SceneItem backgroundImg={item.img} key={index}>
                    <div className='mask'></div>
                    <div className='content'>
                      <div className='title'>{item.title}</div>
                      <div className='desc'>{item.desc}</div>
                    </div>
                  </SceneItem>
                )
              })}
            </SceneItemWrap>
            <SceneItemWrap className='pcScene'>
              {sceneList.slice(3, 6).map((item, index) => {
                return (
                  <SceneItem backgroundImg={item.img} key={index}>
                    <div className='mask'></div>
                    <div className='content'>
                      <div className='title'>{item.title}</div>
                      <div className='desc'>{item.desc}</div>
                    </div>
                  </SceneItem>
                )
              })}
            </SceneItemWrap>
            <SceneItemWrap className='phoneScene'>
              {sceneList.map((item, index) => {
                return (
                  <SceneItem backgroundImg={item.phoneImg} key={index}>
                    <div className='content'>
                      <div className='title'>{item.title}</div>
                    </div>
                  </SceneItem>
                )
              })}
            </SceneItemWrap>
          </SceneWrap>
        </ApplyContainer>
        <HonorContainer>
          <H2Title>荣誉资质</H2Title>
          <SwiperSwitch
            left={'50%'}
            marginLeft='-470px'
            top='30%'
            swiperHeight='516px'
            arrowBg='rgba(255,255,255,0.8)'
            hoverArrowBg='rgba(255,255,255,1)'
            arrowLeft='0px'
            arrowRight='0px'
            fadeNavBg='#6B6CFF'
            containerHeight='305px'
            navBottom='0'
            width='1200px'
          >
            {honorList.edges.map((item, index) => {
              return (
                <div className='content' key={index}>
                  <img className='honorImg' src={item.node.leftImg.fluid.src} alt='' />
                  <div className='honorText'>
                    <img className='logoImg' src={item.node.topImg.fluid.src} alt='' />
                    <p className='text'>{item.node.text.text}</p>
                  </div>
                </div>
              )
            })}
          </SwiperSwitch>
        </HonorContainer>
        <TryBanner />
        <NewsContainer>
          <H2Title>热点资讯</H2Title>
          <TopFiveNews topFiveNews={topFiveNews}></TopFiveNews>
        </NewsContainer>
        <Modal
          simpleHeader
          bodyStyle={{ padding: '0' }}
          visible={showVideo}
          onClose={e => {
            this.CloseVideo(e)
          }}
        >
          <video
            src='/dayan_client_video_2021_09.mp4'
            allowFullScreen={true}
            controls={true}
            autoPlay={true}
            style={{ width: '80vw' }}
            ref={this.setVideoRef}
          />
        </Modal>
        <Modal
          simpleHeader
          bodyStyle={{ padding: '0' }}
          visible={showUpgradeModal}
          closable={false}
          onClose={e => {
            this.CloseModal(e)
          }}
        >
          {/* <UpgradeModalContent className='upgradeMessageModal'>
            <img src={upgradeMessageBg} alt=''></img>
            <img src={upgradeMessageBgMobile} alt=''></img>
            <div>
              <p>尊敬的客户：</p>
              <p>
                为了给您提供更为稳定和优质的服务，我司计划在
                <strong>2022年10月27日0时至2022年10月28日24时</strong>
                进行系统优化和升级，升级期间大雁云官网及客户端将暂停访问。升级过程预计持续48小时，
                <strong>恢复时间（10月29日0时起）</strong>不再另行通知。感谢您的理解和配合。
              </p>
              <p className='phone'>
                如有疑问，请拨打我司24小时全国服务热线：<b>4008808120</b>。
              </p>
              <Divider style={{ borderTop: '1px solid #F3F6F7' }} />
            </div>
            <Button
              className='btn'
              onClick={() => {
                this.setState({ showUpgradeModal: false })
              }}
            >
              我知道了
            </Button>
          </UpgradeModalContent> */}
        </Modal>
      </Layout>
    )
  }
}

export default Index

export const query = graphql`
  query {
    banner: file(relativePath: { regex: "/index/banner.png/" }) {
      ...fluidImage
    }
    sassBanner: file(relativePath: { regex: "/index/sass-banner.png/" }) {
      ...fluidImage
    }
  }
`

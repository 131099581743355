import styled from 'styled-components'
import { Flex, H1, H2, Wrapper } from '@dayancloud/common/components'
import { Media, typography, color, transition } from '@dayancloud/common/theme'
import { rgba } from 'polished'
import ArrowImg from '../images/index/arrow.png'
import ArrowPhoneImg from '../images/index/arrowPhone.png'

export const TopContainer = styled.div`
  background-color: #0d0c17;
  ${Media.lessThan(Media.small)} {
    display: flex;
    flex-direction: column;
    background-color: #f6f7fd;
  }
`

export const H2Title = styled.h2`
  margin-top: 0;
  font-size: 36px;
  font-weight: 500;
  color: #2f323e;
  text-align: center;
  margin-bottom: 64px;
  ${Media.lessThan(Media.small)} {
    font-size: 25px;
    font-weight: 500;
    margin-bottom: 23px;
`

export const BannerContainer = styled.div`
  position: relative;
  height: 100vh;
  z-index: 0;
  background: #ccc;
  font-size: ${typography.h4};
  ${H1} {
    font-size: 60px;
  }
  .desc {
    width: 630px;
    margin-top: 30px;
    line-height: 30px;
  }
  ${Media.lessThan(Media.small)} {
    height: auto;
    width: 100%;
    .desc {
      width: 100%;
      margin-top: 30px;
      font-size: ${typography.textThin};
      line-height: 18px;
    }
    .video {
      margin: 20px 0;
    }
    a {
      width: 150px;
    }
    ${H1} {
      font-size: ${typography.h2};
    }
  }
`

export const VideoPlayBtn = styled(Flex)`
  margin: 41px 0 43px;
  align-items: center;
  ${Media.lessThan(Media.small)} {
    margin: 24px 0 22px;
    span {
      font-size: 15px;
    }
  }
`
export const VideoPlayBtnIcon = styled.span`
  cursor: pointer;
  width: 24px;
  height: 24px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 2px solid currentColor;
  transition: ${transition(['color', 'background-color'])};
  margin-right: 10px;
  :hover {
    color: ${color.primary};
    background-color: ${rgba(color.primary, 0.1)};
  }
  ::after {
    display: block;
    content: '';
    width: 0;
    height: 0;
    margin-right: -4px;
    border-width: 5px 0 5px 10px;
    border-style: solid;
    border-color: currentColor;
    border-top-color: transparent;
    border-bottom-color: transparent;
  }
  ${Media.lessThan(Media.small)} {
    transform: scale(0.7);
    margin-right: 6px;
  }
`
export const ApplyContainer = styled.div`
  background: #ffffff;
  padding: 0 0 105px;
  ${Media.lessThan(Media.small)} {
    padding: 0;
  }
`
export const Advantage = styled.div`
  max-width: 100%;
  padding-top: 100px;
  ${Media.lessThan(Media.small)} {
    padding: 30px 22px 30px 22px;
  }
`
export const AdvantageWrap = styled(Wrapper)`
  color: #2f323e;
  padding: 4px 0 40px;
  border-radius: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${H2} {
    font-size: 36px;
    font-weight: 500;
    color: #2f323e;
    br {
      display: none;
    }
  }
  ${Media.lessThan(Media.small)} {
    border-radius: 10px;
    padding: 0;
    ${H2} {
      font-size: 25px;
      font-weight: 500;
      color: #2f323e;
      margin-bottom: 10px;
      br {
        display: block;
      }
    }
  }
`
export const AdvantageItemWrap = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 74px;
  ${Media.lessThan(Media.small)} {
    justify-content: space-around;
    margin-top: 20px;
  }
`
export const AdvantageItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ::after {
    content: '';
    background: url(${ArrowImg});
    width: 22px;
    height: 12px;
    position: relative;
    top: -24%;
    left: 140px;
  }
  :last-child&&::after {
    visibility: hidden;
  }
  img {
    width: 167px;
    height: 157px;
  }
  .hoverImg {
    display: none;
  }
  .text {
    margin-top: 24px;
  }
  .content {
    height: 36px;
    margin-top: 10px;
    .contentTitle1,
    .contentTitle2 {
      font-size: 14px;
      color: #828995;
      line-height: 22px;
      :hover {
        color: #6b6cff;
      }
    }
  }
  :hover {
    .hoverImg {
      display: block;
    }
    .dishoverImg {
      display: none;
    }
    :first-child .text,
    :nth-child(2) .text {
      color: #6b6cff;
    }
    :nth-child(2) .contentTitle1 {
      color: #828995;
    }
  }
  ${Media.lessThan(Media.small)} {
    position: relative;
    width: 48%;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    img {
      width: 114px;
      height: 104px;
    }
    ::after {
      background: url(${ArrowPhoneImg});
      width: 19px;
      height: 10px;
      top: -30%;
      left: 49%;
    }
    :nth-child(2)&&::after {
      top: 5%;
      left: -54%;
      transform: rotate(154deg);
    }
    .text {
      font-size: 14px;
      ::before {
        display: none;
      }
    }
    .contentTitle1,
    .contentTitle2 {
      font-size: ${typography.textThin};
      line-height: 18px;
    }
  }
`
export const SceneWrap = styled(Wrapper)`
  padding-top: 99px;
  .phoneScene {
    display: none;
  }
  .pcScene:nth-of-type(1) {
    margin-bottom: 24px;
  }
  ${Media.lessThan(Media.small)} {
    padding: 30px 0px;
    .pcScene {
      display: none;
    }
    .phoneScene {
      display: flex;
      justify-content: center;
    }
  }
`
export const SceneItemWrap = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  gap: 24px;

  ${Media.lessThan(Media.small)} {
    flex-wrap: wrap;
    gap: 0;
  }
`
export const SceneItem = styled.div`
  position: relative;
  width: 384px;
  height: 384px;
  background-image: url(${p => p.backgroundImg});
  color: #ffffff;
  overflow: hidden;
  transition-property: flex-shrink, width;
  transition-duration: 0.65s;
  border-radius: 4px;
  .content {
    position: absolute;
    height: 100%;
    top: 0;
    left: 34px;
    right: 34px;
    color: #fff;
    margin-top: 304px;
    transition: margin-top 0.65s;
  }
  .mask {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    background: rgba(107, 108, 255, 0.8);
    backdrop-filter: saturate(100%) blur(3px);
  }
  .title {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 44px;
    ::after {
      content: '';
      position: absolute;
      left: 0;
      width: 24px;
      border-top: 2px solid #ffffff;
      top: 55px;
      opacity: 0;
      transition: opacity 0.65s;
    }
  }
  .desc {
    width: 516px;
    font-size: 16px;
    font-weight: 400;
  }
  :hover {
    width: 584px;
    flex-shrink: 0;
    .mask {
      opacity: 1;
    }
    .content {
      margin-top: 192px;
      height: auto;
    }
    .desc {
      margin-bottom: 40px;
    }
    .title {
      ::after {
        opacity: 1;
      }
    }
  }
  ${Media.lessThan(Media.small)} {
    width: 167px;
    height: 192px;
    margin: 0 5.5px 11px 5.5px;
    border-radius: 2px;
    background-size: 100% 100%;
    .mask {
      display: none;
    }
    :hover {
      width: 48%;
      .content {
        margin-top: 152px;
        left: 15px;
      }
    }
    .content {
      left: 15px;
      margin-top: 152px;
    }
    .title {
      font-size: 17px;
      :after {
        display: none;
      }
    }
  }
`

export const FeatureItemsContainer = styled.div`
  background: #f6f7fd;
`

export const FeatureItemWrap = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 0 70px;
  ${Media.lessThan(Media.small)} {
    padding: 30px 28px;
  }
`
export const FeatureItem = styled.div`
  margin-right: 30px;
  width: 380px;
  height: 140px;
  background: #ffffff;
  border: 2px solid #ffffff;
  box-shadow: 0px 10px 20px 0px rgba(181, 181, 181, 0.1);
  border-radius: 4px;
  :hover {
    background: linear-gradient(0deg, #fefeff 0%, #eaeafe 100%);
  }
  :last-child {
    margin-right: 0px;
  }
  .content {
    margin-left: 20px;
  }
  .title {
    font-weight: 500;
    font-size: 20px;
    line-height: 40px;
  }
  .desc {
    font-size: ${typography.textSmall};
    width: 241px;
    color: #646c7a;
    line-height: 24px;
  }
  ${Media.lessThan(Media.small)} {
    width: 100%;
    height: auto;
    flex-direction: row;
    justify-content: flex-start;
    text-align: left;
    margin: 6px 0;
    img {
      margin-right: 17px;
    }
    .content {
      margin-left: 0;
    }
    .title {
      font-size: 17px;
      color: #2f323e;
    }
    .desc {
      font-size: ${typography.textThin};
      width: 100%;
    }
  }
`

export const DescWrap = styled.div`
  display: flex;
  font-size: 16px;
  color: ${color.text};
  max-width: 1200px;
  margin: 50px auto 0;
  .schedule {
    display: flex;
    margin-top: 14px;
    display: flex;
    flex-direction: column;
    height: 390px;
    justify-content: space-between;
    position: relative;
    margin-left: 66px;
    ::before {
      content: '';
      width: 1px;
      height: 334px;
      background: #6b6cff;
      opacity: 0.3;
      position: absolute;
      left: -37px;
      top: 18px;
    }
  }
  img {
    width: 15px;
    height: 358px;
    margin: 7px 30px 0 0;
  }
  ul {
    margin: 0;
    padding: 0;
  }
  li {
    width: 300px;
    position: relative;
    list-style-type: none;
    font-size: 14px;
    color: #828995;
    line-height: 24px;
    ::before {
      display: inline-block;
      content: '';
      width: 7px;
      height: 7px;
      background: #6b6cff;
      border-radius: 50%;
      position: absolute;
      left: -40px;
      top: 8px;
    }
  }
  li:nth-child(1) {
    font-size: 20px;
    font-weight: 500;
    color: #2f323e;
    line-height: 24px;
    ::after {
      display: block;
      content: '';
      width: 15px;
      height: 15px;
      background: #6b6cff;
      border: 1px solid #6b6cff;
      opacity: 0.3;
      border-radius: 50%;
      position: relative;
      left: -44px;
      top: -20px;
    }
  }
  .videoContent {
    border: 2px solid #ffffff;
    background: linear-gradient(0deg, #f4f4ff 0%, #fefeff 100%);
    box-shadow: 0px 0px 20px 0px rgba(181, 181, 181, 0.2);
    border-radius: 8px;
    margin: 0 0 100px 80px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  video {
    width: 712px;
    border-radius: 8px;
    margin: 4px;
  }
  ${Media.lessThan(Media.small)} {
    width: 100%;
    flex-wrap: wrap;
    margin: auto;
    .schedule {
      padding: 0 50px 0 87px;
      margin-top: 0;
      overflow: hidden;
      height: auto;
      margin-left: 0px;
      ::before {
        height: 100%;
        left: 49px;
      }
    }
    img {
      width: 13px;
      height: 300px;
      margin: 4px 24px 0 0;
    }
    li {
      width: 100%;
      margin-bottom: 19.5px;
      font-size: ${typography.textThin};
      line-height: 20.4px;
      ::before {
        width: 5.95px;
        height: 5.95px;
      }
    }
    li:nth-child(1) {
      height: 16px;
      font-size: 17px;
      line-height: 20px;
      ::after {
        width: 12.75px;
        height: 12.75px;
        top: -15.3px;
        left: -43.7px;
      }
    }
    ul:last-child > li:last-child::after {
      content: '';
      width: 2px;
      height: 50px;
      background: #f6f7fd;
      position: absolute;
      left: -38px;
      top: 14px;
    }
    .videoContent {
      height: auto;
      padding: 1px;
      margin: 20px 15px 30px;
    }
    video {
      width: 100%;
      margin: 2px;
    }
  }
`

export const HonorContainer = styled.div`
  padding: 100px 300px;
  background: #f6f7fd;
  display: flex;
  flex-direction: column;
  align-items: center;
  .content {
    width: 940px;
    height: 448px;
    background: #ffffff;
    box-shadow: 0px 0px 16px 4px rgba(107, 108, 255, 0.05);
    border-radius: 10px;
    display: flex;
    padding: 60px;
    .honorImg {
      height: 328px;
      margin-right: 40px;
    }
  }
  .honorText {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    justify-content: center;
    color: #828995;
    .logoImg {
      height: 44px;
      width: 156px;
    }
    .text {
      ::before {
        display: block;
        content: '';
        width: 24px;
        height: 4px;
        background: #6b6cff;
        border-radius: 2px;
        margin: 30px 0;
      }
    }
  }
  ${Media.lessThan(Media.small)} {
    padding: 30px 15px;
    display: block;
    .content {
      width: 100%;
      height: 280px;
      display: flex;
      padding: 27px 22px;
      flex-direction: column-reverse;
      align-items: center;
      text-align: center;
      justify-content: center;

      .honorImg {
        width: 162px;
        height: auto;
        margin-right: 0;
      }
    }
    .honorText {
      font-size: ${typography.textThin};
      .logoImg {
        display: none;
      }
      .text {
        ::before {
          display: none;
        }
      }
    }
  }
`
export const NewsContainer = styled.div`
  padding: 85px 99px;
  background: #ffffff;
  ${Media.lessThan(Media.small)} {
    padding: 30px 0 49px;
  }
`
export const ZixunImgWrap = styled.div`
  position: fixed;
  right: 30px;
  bottom: 130px;
  cursor: pointer;
  ${Media.lessThan(Media.small)} {
    display: none;
  }
`
export const FeatureItemContainer = styled.div`
  position: absolute;
  width: 100%;
  bottom: 10px;
  z-index: 2;
  min-width: 1200px;
  ${Media.lessThan(Media.small)} {
    position: static;
    top: 480px;
    min-width: auto;
  }
`
export const FeatureItemList = styled.div`
  padding: 20px 26px 35px;
  display: flex;
  img {
    width: 58px;
    height: 58px;
  }
  ${Media.lessThan(Media.small)} {
    padding: 18px 21px;
    img {
      width: 49px;
      height: 49px;
    }
    > div {
      > svg {
        width: 20px;
        height: 47px;
      }
    }
  }
`
export const UpgradeModalContent = styled.div`
  width: 700px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    width: 702px;
    height: 221px;
    margin-top: -1px;
  }
  img:nth-child(2) {
    display: none;
  }
  .btn {
    height: 40px;
    width: 160px;
    margin: 30px 0 40px;
  }
  > div {
    padding: 12px 42px 0;
    > p {
      > strong {
        color: #6565f9;
      }
    }
  }
  .phone {
    margin-bottom: 40px;
  }
  ${Media.lessThan(Media.small)} {
    width: 335px;
    font-size: 12px;
    img:nth-child(1) {
      display: none;
    }
    img:nth-child(2) {
      display: block;
      width: 335px;
      height: 131px;
    }
    .btn {
      height: 25px;
      width: 100px;
      font-size: 12px;
      font-weight: bold;
      line-height: 1;
      margin: 20px 0 30px;
    }
    > div {
      padding: 0 22px;
    }
    .phone {
      margin-bottom: 20px;
    }
  }
`

import React from 'react'
import styled from 'styled-components'
import { Media, color } from '@dayancloud/common/theme'
import { Billboard, Button } from '@dayancloud/common/components'
import { graphql, useStaticQuery } from 'gatsby'
import AppletbannerTitleImg from '../../images/index/appletbannerTitle.png'
import AppletbannerTitleMobileImg from '../../images/index/appletbannerTitleMobile.png'

const AppletBannerWrapper = styled.div`
  color: ${color.white};
  height: 100vh;
  ${Media.lessThan(Media.small)} {
    height: 480px;
  }
`
const AppletBannerContent = styled.div`
  width: 643px;
  height: calc(100vh - 100px);
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .applet-box {
    margin: -56px 0 0 -274px;
    img:nth-child(1) {
      display: none;
    }
    img {
      display: block;
      margin-bottom: 50px;
    }
    a {
      display: block;
      width: 170px;
      height: 44px;
    }
  }
  ${Media.lessThan(Media.small)} {
    width: 375px;
    height: 100%;
    .applet-box {
      margin: auto;
      margin-top: 80px;
      img:nth-child(1) {
        display: block;
      }
      img:nth-child(2) {
        display: none;
      }
      img {
        margin-bottom: 22px;
      }
      a {
        width: 113px;
        height: 30px;
        line-height: 30px;
        margin: auto;
        font-size: 12px;
      }
    }
  }
`

const AppletBanner = React.memo(function() {
  const data = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { regex: "/index/appletbanner.png/" }) {
        ...fluidImage
      }
      bannerMobile: file(relativePath: { regex: "/index/appletbannerMobile.png/" }) {
        ...fluidImage
      }
    }
  `)
  return (
    <AppletBannerWrapper>
      <AppletBannerContent>
        <div className='applet-box'>
          <img src={AppletbannerTitleMobileImg} alt=''></img>
          <img src={AppletbannerTitleImg} alt=''></img>
          <Button as='a' href='https://www.dayancloud.com/news/post-id-1232/' rel='nofollow'>
            了解详情
          </Button>
        </div>
      </AppletBannerContent>
      <Billboard
        fluid={[
          data.bannerMobile.childImageSharp.fluid,
          { ...data.banner.childImageSharp.fluid, media: '(min-width: 780px)' },
        ]}
      />
    </AppletBannerWrapper>
  )
})

export default AppletBanner

import React from 'react'
import styled from 'styled-components'
import { Button } from '../../../../common/components'
import { typography, Media } from '../../../../common/theme'
import phoneIcon from '../../images/index/icon_phone_purple.png'
import contactUs from '../../images/index/contactUs.png'

const ContactUsWrapper = styled.div`
  height: 164px;
  background-image: url(${contactUs});
  background-size: 100%;
  color: white;
  ${Media.lessThan(Media.small)} {
    height: 155px;
  }
`
const ContactUsContainer = styled.div`
  margin: 0 auto;
  height: 100%;
  max-width: 1200px;
  display: flex;
  align-items: center;
  h3 {
    margin: 0 0 15px;
    font-size: ${typography.h3};
    font-weight: 500;
  }
  text {
    font-size: ${typography.text};
  }
  button {
    margin-left: 17rem;
    border-radius: 4px;
    display: flex;
    align-items: center;
    :hover,
    &:active,
    &:focus {
      background-color: #ffffff;
      color: #6b6cff;
    }
  }
  img {
    margin-right: 8px;
  }
  ${Media.lessThan(Media.small)} {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0 19px;
    div {
      text-align: center;
    }
    h3 {
      margin: 0 0 9px;
      font-size: 19px;
    }
    text {
      font-size: ${typography.textThin};
    }
    button {
      width: 150px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 14px auto 0;
    }
  }
`
class ContactUs extends React.PureComponent {
  render() {
    return (
      <ContactUsWrapper>
        <ContactUsContainer>
          <div>
            <h3>无人机航拍\无人机倾斜摄影、测绘服务</h3>
            <text>
              大雁云与多家专业航拍、测绘公司合作，也提供一站式航拍到模型交付全流程服务，联系我们沟通需求~
            </text>
          </div>
          <Button variant='outlined' id='lxwm-chfw'>
            <img src={phoneIcon} alt='' />
            <text>400-880-8120</text>
          </Button>
        </ContactUsContainer>
      </ContactUsWrapper>
    )
  }
}

export default ContactUs

import React from 'react'
import styled from 'styled-components'
import { H2 } from '../../../../common/components'
import { typography, Media } from '../../../../common/theme'

import CloudIcon01 from '../../images/index/cloudIcon01.png'
import CloudIcon02 from '../../images/index/cloudIcon02.png'
import CloudIcon03 from '../../images/index/cloudIcon03.png'
import CloudIcon04 from '../../images/index/cloudIcon04.png'
import CloudIcon05 from '../../images/index/cloudIcon05.png'
import CloudIcon06 from '../../images/index/cloudIcon06.png'
import CloudIconHover01 from '../../images/index/cloudIconHover01.png'
import CloudIconHover02 from '../../images/index/cloudIconHover02.png'
import CloudIconHover03 from '../../images/index/cloudIconHover03.png'
import CloudIconHover04 from '../../images/index/cloudIconHover04.png'
import CloudIconHover05 from '../../images/index/cloudIconHover05.png'
import CloudIconHover06 from '../../images/index/cloudIconHover06.png'
import CloudHoverBg01 from '../../images/index/cloudHoverBg01.png'
import CloudHoverBg02 from '../../images/index/cloudHoverBg02.png'
import CloudHoverBg03 from '../../images/index/cloudHoverBg03.png'
import CloudHoverBg04 from '../../images/index/cloudHoverBg04.png'
import CloudHoverBg05 from '../../images/index/cloudHoverBg05.png'
import CloudHoverBg06 from '../../images/index/cloudHoverBg06.png'

const CloudServiceContainer = styled.div`
  padding: 100px 0;
  background: #ffffff;
  ${H2} {
    text-align: center;
    font-size: 36px;
    color: #2f323e;
    margin-bottom: 64px;
    font-weight: 500;
  }
  ${Media.lessThan(Media.small)} {
    padding: 30px 15px;
    ${H2} {
      font-size: 25px;
      font-weight: 500;
      margin-bottom: 25px;
    }
  }
`
const SceneItemWrap = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
`
const SceneItem = styled.div`
  margin-bottom: 16px;
  overflow: hidden;
  width: 389px;
  height: 234px;
  color: #2f323e;
  padding: 38px 30px;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 0px 20px 0px rgba(181, 181, 181, 0.2);
  background-image: url(${p => (p.hoverBg ? p.hoverBg : '')});
  background-repeat: no-repeat;
  background-position: 0 -234px;
  transition: background-position 0.65s;
  img {
    width: 44px;
    height: 44px;
  }
  .hoverImg {
    display: none;
  }
  .title {
    margin-top: 10px;
    font-size: 20px;
    font-weight: 500;
    z-index: 1;
    line-height: 28px;
    height: 19px;
    color: #2f323e;
  }
  .desc {
    margin-top: 16px;
    width: 327px;
    height: 39px;
    font-size: ${typography.textSmall};
    font-weight: 400;
    color: #828995;
    line-height: 24px;
  }
  :hover {
    background-size: 100% 100%;
    background-position: 0 0;
    transition: background-position 0.65s;
    .title,
    .desc {
      color: #ffffff;
    }
    .hoverImg {
      display: block;
    }
    .dishoverImg {
      display: none;
    }
  }
  ${Media.lessThan(Media.small)} {
    width: 48%;
    height: 244px;
    padding: 20px;
    img {
      width: 38px;
      height: 38px;
    }
    .title {
      margin-top: 18px;
      font-size: 17px;
      line-height: 19px;
      height: 19px;
      color: #2f323e;
    }
    .desc {
      width: 100%;
      line-height: 18px;
      margin-top: 10px;
      font-size: ${typography.textThin};
    }
  }
`

const sceneList = [
  {
    icon: CloudIcon01,
    hoverIcon: CloudIconHover01,
    hoverBg: CloudHoverBg01,
    title: '智能建模',
    desc:
      '构建在云端的高精度无人机航拍实景三维重建云平台，集高精度定位、云计算及专业三维建模引擎为一体。',
  },
  {
    icon: CloudIcon02,
    hoverIcon: CloudIconHover02,
    hoverBg: CloudHoverBg02,
    title: '按需付费',
    desc:
      '无需单独订购应用程序License与服务，一站式云服务，按需使用，按量计费，建模成本比自购机器节省70%以上。',
  },
  {
    icon: CloudIcon03,
    hoverIcon: CloudIconHover03,
    hoverBg: CloudHoverBg03,
    title: '海量云算力',
    desc:
      '依托我司渲染业务的十几年产品和服务优势，为您提供稳定可靠、澎湃算力支持的重建云服务，用户无需自购机器，节省运维成本。',
  },
  {
    icon: CloudIcon04,
    hoverIcon: CloudIconHover04,
    hoverBg: CloudHoverBg04,
    title: '秒级调度',
    desc:
      '高弹性节点资源，1秒内可同时调度上万台资源，提高三维重建的计算速度，成倍提升测绘行业数据处理效率，助力行业产业升级。',
  },
  {
    icon: CloudIcon05,
    hoverIcon: CloudIconHover05,
    hoverBg: CloudHoverBg05,
    title: '安全稳定',
    desc:
      '凭借多年稳定安全管理体系以及IT系统建设，我司视觉云计算服务成为全球可信赖的合作伙伴（TPN权威认证），数据中心亦通过ISO27001安全认证。',
  },
  {
    icon: CloudIcon06,
    hoverIcon: CloudIconHover06,
    hoverBg: CloudHoverBg06,
    title: '贴心服务',
    desc:
      '为提供高速上传、下载服务，大雁云客户端内置专业传输引擎；为保障用户体验，提供7×24小时客户服务，实时在线技术解答。',
  },
]

class CloudService extends React.PureComponent {
  render() {
    return (
      <CloudServiceContainer>
        <H2>大雁云实景三维重建云服务</H2>
        <SceneItemWrap>
          {sceneList.map((item, index) => {
            return (
              <SceneItem hoverBg={item.hoverBg} key={index}>
                <img className='dishoverImg' src={item.icon} alt='' />
                <img className='hoverImg' src={item.hoverIcon} alt='' />
                <div className='title'>{item.title}</div>
                <div className='desc'>{item.desc}</div>
              </SceneItem>
            )
          })}
        </SceneItemWrap>
      </CloudServiceContainer>
    )
  }
}

export default CloudService
